.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.spinner {
  color: #2499c6;
}
.btn-spinner {
  font-size: 10px;
  margin-right: 5px;
}
.not-found {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.PhoneInputInput {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent !important;
}
.PhoneInputInput:focus {
  outline: none;
  box-shadow: none;
}
.page-wrapper {
  flex-grow: 1;
  padding-bottom: 1rem;
}
.welcome-header-title {
  font-size: 26px;
}
.page-not-found {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}
