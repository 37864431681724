.register-wrapper {
    background-image: linear-gradient(to bottom, #ffffff, #fffbfeba),
      url('../../images/Landing-page-background.png');
    background-size: cover;
    background-color: #ffffff;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textbox {
  border: 0.5px solid #155783;
  border-radius: 5px;
  width: auto;
  height: 40px;
  font-size: 100%;
  font-weight: 300;
  padding: 0 10px;
  margin: 10px auto;
  width: 100%;
}

.textbox:focus {
  outline: none;
}

.register-header {
  text-align: center;
} 

.welcome-footer {
  margin: auto;
  margin-top: 50px;
  width: 60%;
}
.welcome-page-wrapper {
  /* margin: 3em; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-img {
  height: 100vh;
  background-image: url('../../images/homepage.png');
  background-position: center;
  background-size: cover;
}
.register-form {
  width: 80%;
  margin: auto;
  overflow: auto;
  min-height: 65vh;
  background-color: #ffffff;
  box-shadow: -2px 1px 12px 2px rgba(181,223,255,0.64);
  padding: 2em;
}

.register-form__resend-code {
  color: #1C99C5;
  cursor: pointer;
}

@media screen and (max-width: 769px) {
  .welcome-page {
    display: none;
  }
  .welcome-page-wrapper {
    margin: 0;
  }
  .welcome-footer {
    margin-top: 50vh;
    width: 100%;
  }
  .login-img {
    position: absolute;
    width: 100%;
    height: 50%;
    z-index: -1;
  }
  .register-form {
    width: 100%;
    overflow: auto;
    padding: 10px;
  }
}



.input-left-side {
  height: 59%;
  margin: auto;
  padding-left: 10%;
}

.button-section {
  margin: 0 auto;
}

.forgot-password-link {
  display: inline-block;
  text-decoration: underline;
}


.register-option-section{
  margin: 0 auto;
}

.btn-new-user {
  border-radius: 50px;
  padding: .8rem 2%;
  height: 12%;
  font-size: 1.2rem;
  }


.bg-image-right-side img {
  height: 100%;
  width: 100%;
}
