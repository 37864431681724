@import url('https://fonts.googleapis.com/css?family=Nunito:wght@200..900');
@import url('https://fonts.googleapis.com/css?family=Nunito Sans:wght@200..900');

body,
html {
  font-size: 16px;
  font-family: 'Nunito Sans', Nunito, sans-serif;
  font-weight: 200;
  line-height: normal;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #ffffff;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
}

.arrow-icon {
  border: solid #000000;
  border-width: 0 2px 2px 0;
  padding: 3px;
  margin-left: 5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.main-content {
  margin: auto;
}
.btn-primary,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:disabled {
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  background-color: #1c99c5 !important;
  color: #ffffff !important;
  margin: 20px 0;
  font-size: 100%;
  box-shadow: none;
  cursor: pointer;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:disabled {
  border: none;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  background-color: #e8f5f9 !important;
  color: #1c99c5 !important;
  margin: 20px 0;
  font-size: 100%;
  box-shadow: none;
  cursor: pointer;
}
.btn:disabled {
  background-color: #c4c4c4;
  cursor: not-allowed;
}
.btn-primary:disabled {
  background-color: #c4c4c4;
  cursor: not-allowed;
}

.btn-link {
  color: #1c99c5;
  border: none;
  background-color: transparent;
  font-size: 90%;
  font-weight: 300;
  cursor: pointer;
}
.btn-outline {
  border-color: #1c99c5;
  border-radius: 5px;
  height: 40px;
  width: 100px;
  margin: 10px;
  cursor: pointer;
}
.btn:focus,
.btn-outline:focus,
.btn-link:focus,
.btn-link:hover,
.btn:hover,
.btn-outline:hover {
  outline: none;
  color: inherit;
  background-color: inherit;
}

.btn-container {
  margin: 10px;
}

.subject-wrapper {
  height: inherit;
  background-color: #ffffff;
  overflow: hidden;
}
.subject-wrapper-header {
  height: 50px;
}
.option-list {
  margin-bottom: 10em;
}

.nav-button {
  margin: auto;
  justify-content: center;
  bottom: 0px;
  font-size: 90%;
  width: 60%;
}

.correct-option {
  margin-left: 5px;
}
.incorrect-option {
  margin-left: 5px;
  color: #da2e18;
}

.active {
  background-color: #6bbe17;
}

.form-field:focus {
  border-color: #1c99c5;
  box-shadow: 0 0 2px #1c99c5;
  outline: none;
}
.form-field {
  height: 38px;
}

.invalid {
  color: #ff0000;
}

.card-icon {
  font-weight: 200;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}
.tag-wrapper {
  border: 1px solid #1c99c5;
  padding: 5px;
  font-size: 90%;
  color: #1c99c5;
  border-radius: 15px;
}

@media screen and (max-width: 768px) {
  .main-content {
    width: 100%;
    background-color: #ffffff;
  }
  html {
    font-family: 'IBM Plex Sans', sans-serif;
  }
  .subject-wrapper {
    height: inherit;
    background-color: #ffffff;
    margin: 20px 0;
    overflow: auto;
  }
  .subject-wrapper-header {
    height: 50px;
  }
}
