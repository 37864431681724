.login-page-wrapper {
  min-height: 100vh;
  display: flex;
  padding-top: 1.8rem;
  padding-left: 2.2rem;
}

.form-elements-container {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
}

.forgort-password {
  display: flex;
  justify-content: center;
}

.forgort-password p,
.register-button {
  cursor: pointer;
  color: #1c99c5;
}

.login-button-container {
  display: flex;
  justify-content: center;
}

.btn-login {
  width: 6rem;
  height: 2.5rem;
  border-radius: 2rem;
}

.btn-login:hover {
  width: 6rem;
  height: 2.5rem;
  border-radius: 2rem;
}

.new-user-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.new-user-button {
  width: 50%;
  border-radius: 1rem;
}

.new-user-button:hover {
  width: 50%;
  border-radius: 1rem;
}
.login-form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 7rem;
  padding-left: 7rem;
}

.login-background-image {
  height: 70vh;
  flex-grow: 1;
  background-image: url('../../../images/homepage.png');
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 850px) {
  .new-user-button {
    width: 70%;
  }
}

@media screen and (max-width: 769px) {
  .login-background-image {
    display: none;
  }

  .login-page-wrapper {
    padding: 0;
  }
  .new-user-button {
    width: 50%;
  }
}

@media screen and (max-width: 450px) {
  .login-form-buttons {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
