.reset-password-page-wrapper {
  min-height: 100vh;
  display: flex;
  padding-top: 1.8rem;
  padding-left: 2.2rem;
}

.reset-password-info-container {
  display: flex;
  flex-direction: column;
  flex-basis: 35%;
  padding: 1rem;
  justify-content: center;
}

.reset-password__arrow--back {
  color: #1c99c5;
}

.verification-code__resend-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verification-code__resend {
  color: #1c99c5;
  cursor: pointer;
}

@media screen and (max-width: 769px) {
  .login-background-image {
    display: none;
  }
  .reset-password-info-container {
    flex-basis: 100%;
  }
}
