.slider-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.slider {
  height: 95%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.arrow-wrapper {
  border-radius: 50%;
  background-color: #d2ebf3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
}

.arrow {
  font-size: 2rem;
  color: #1c99c5;
}

.hideElement {
  visibility: hidden;
}

.slider-dots-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-dot {
  color: #a5d6e8;
  font-size: 3rem;
  cursor: pointer;
}

.current-dot {
  color: #1c99c5;
}

@media screen and (max-width: 480px) {
  .slider {
    width: 96%;
    padding: 0;
  }

  .arrow {
    font-size: 1.3rem;
  }

  .slider-dot {
    font-size: 1.5rem;
  }

  .slider-dots-container {
    margin-top: -3rem;
  }
}
