.free-trial {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.free-trial__image-wrapper {
  height: 50vh;
}

.free-trial__image-wrapper > img {
  object-fit: contain;
  height: 100%;
}

.free-trial__message-wrapper {
  flex-basis: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  color: #000;
}

.free-trial__cta {
  text-align: center;
  font-family: Nunito;
  font-size: 3rem;
  font-weight: 900;
  line-height: 3rem;
  padding-bottom: 1.5rem;
}

.free-trial__message {
  width: 29.37rem;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 1.5rem;
}

.free-trial__button-container {
  display: flex;
  justify-content: center;
}

.free-trial__button {
  color: #fff;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.1125rem;
  text-transform: uppercase;
  padding: 1rem 1.875rem;
  border-radius: 3.125rem;
  background: #1c99c5;
  border: none;
}
