.page-footer {
  width: 100%;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  color: #ffffff;
  visibility: visible;
  
}
.footer-logo {
  height: 60px;
}
@media screen and (max-width: 768px) {
  .page-footer {
    bottom: 0;
    width: 100%;
  }
}
