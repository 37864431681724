.header-logo {
  height: 100px;
  display: flex;
  justify-content: center;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #a8aaaa;
}

.header__username {
  padding-left: 1rem;
}

.header__icon {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header-image {
  height: 80px;
}
.image-header {
  height:150px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  background-color: #0D5274;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0.25rem;
  }
  .header-logo {
    height: 80px;
  }
  .image-header {
    height:100px;
  }
  .header-image {
    height: 70px;
  }
}
