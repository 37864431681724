.light-blue-background {
  background-color: #d2ebf3;
}

.landing-page {
  background-color: #ffffff;
  min-height: 100vh;
  font-weight: 400;
}

.landing-page-header {
  height: 150px;
  display: flex;
  justify-content: center;
}

.landing-page-section {
  display: flex;
}

.landing-page-first-section {
  height: 75vh;
  align-items: center;
  justify-content: center;
}

.landing-page-subsection {
  flex: 50%;
  height: 100%;
  width: 100%;
}

.hero-image-container {
  display: flex;
  justify-content: flex-end;
}

.hero-image-container > img {
  object-fit: contain;
}

.hero-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.thirty-days-free-trial__cta {
  font-weight: 900;
  font-size: 3rem;
  text-align: center;
}

.thirty-days-free-trial__message {
  font-size: 2.25rem;
  text-align: center;
}

.thirty-days-free-trial__buttons {
  padding: 2rem 0 1.5rem 0;
  display: flex;
}

.section-btns {
  text-transform: uppercase;
  border-radius: 3.125rem;
  padding: 0.5rem 3.06rem;
  letter-spacing: 0.15rem;
  text-align: center;
}

.start-learning__btn,
.start-learning__btn:hover {
  background-color: #1c99c5;
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
}

.free-trial__login-btn,
.free-trial__login-btn:hover {
  text-decoration: none;
  color: inherit;
  padding: 0.25rem 3.06rem;
  border: 5px solid #1c99c5;
  font-weight: 700;
}

.show-more__link,
.show-more__link:hover {
  text-decoration: none;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375;
  color: #1c99c5;
  text-transform: capitalize;
}

.revision-modes-container {
  height: 44rem;
  display: flex;
}

.revision-modes {
  flex: 40 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
}

.slider-container {
  flex: 60 1;
  height: 100%;
  width: 27rem;
}

.show-more-container {
  width: 26%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.landing-page-fourth-wrapper {
  display: none;
}

.landing-page-second {
  width: 90%;
  height: 800px;
  margin: auto;
  background-color: #fff;
  border-radius: 40px;
  position: relative;
}

.landing-page-third {
  margin: auto;
  position: relative;
}

.show-more-button {
  text-transform: uppercase;
  background-color: #1c99c5;
  color: #ffffff;
  width: 60%;
  padding: 0.5rem;
  border-radius: 2rem;
  margin-bottom: 1rem;
}

.show-more-button:hover {
  background-color: #1c99c5;
  color: #ffffff;
  width: 60.5%;
}

.auth-section {
  height: 30rem;
}

.register-cta-container {
  background-color: #f4f7fa;
  display: flex;
  height: 21rem;
}

.register-cta {
  display: flex;
  flex: 50%;
  justify-content: center;
  align-items: center;
  height: 95%;
  padding-left: 6rem;
}

.register-hero {
  background-image: url('../../images/register-hero.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.landing-page-register-container {
  flex: 50% 1;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
}

.landing-page-register-button,
.landing-page-register-button:hover {
  text-transform: uppercase;
  background-color: #1c99c5;
  color: #ffffff;
  width: 40%;
  padding: 16px 49px 16px 49px;
  width: 257px;
  height: 54px;
  border-radius: 2rem;
  font-size: 1rem;
  line-height: 22px;
}

.login-cta-container {
  display: flex;
  height: 9rem;
  background-color: #ffe39c;
}

.login-cta-container div,
.login-cta {
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-cta p {
  font-size: 2rem;
  margin-bottom: 0;
}

.footer-login-button,
.footer-login-button:hover {
  text-transform: uppercase;
  background-color: #ffffff;
  color: #000000;
  width: 257px;
  padding: 0.5rem;
  border-radius: 2rem;
  margin-top: 2.3rem;
  border: 3px solid #1c99c5;
  margin-top: 0;
}

.footer {
  background-color: #0d5274;
  color: #ffffff;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.landing-logo {
  margin-top: 1em;
  height: 150px;
}
.eneza-logo {
  height: 60px;
}

.landing-page-section-wrapper {
  display: flex;
  margin: auto;
  width: 90%;
  height: 80vh;
}

.comprehension-section p {
  margin-right: 6rem;
}

@media screen and (max-width: 768px) {
  .landing-page-header {
    display: flex;
    justify-content: center;
  }

  .landing-page-section {
    flex-direction: column;
    width: 100%;
  }

  .landing-page-first-section {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom: 1.5rem;
  }
  .hero-image-container {
    justify-content: center;
  }
  .hero-image-container > img {
    object-fit: contain;
    height: 60vh;
    width: 100%;
  }

  .thirty-days-free-trial__cta {
    font-weight: 800;
    font-size: 2.3rem;
  }

  .thirty-days-free-trial__message {
    font-size: 1.75rem;
  }

  .thirty-days-free-trial__buttons {
    flex-direction: column;
    gap: 1rem;
  }

  .section-btns {
    width: 95%;
  }
  .landing-logo {
    margin-top: 10px;
    height: 100px;
  }

  .landing-page-fourth-wrapper {
    display: block;
    margin: auto;
    width: 90%;
  }

  .landing-page-header {
    height: 100px;
  }
  .landing-page-second {
    border-radius: 20px;
    height: 100%;
  }
}

@media screen and (max-width: 480px) {
  .line-break:after {
    content: '\a';
    white-space: pre;
  }

  .hero-text-container {
    flex-basis: 20%;
    padding: 0;
  }

  .create-account-hero-wrapper,
  .login-hero-wrapper {
    padding-bottom: 1rem;
  }

  .create-account-hero-image {
    padding: 1rem 0 0 2rem;
    height: 100%;
  }

  .show-me-how {
    margin-top: 1rem;
  }

  .auth-section {
    height: 33rem;
  }

  .revision-modes-container {
    flex-direction: column;
    height: 40rem;
  }

  .revision-modes {
    flex: 30 1;
    font-size: 1.5rem;
    align-items: flex-end;
    margin: 0;
  }

  .slider-container {
    flex: 70 1;
    width: auto;
  }

  .show-more-container {
    width: 80%;
    display: flex;
    justify-content: center;
  }
  .register-cta,
  .login-cta {
    flex-direction: column;
    padding: 0;
  }

  .register-hero {
    margin: 0 0 0 4rem;
  }

  .login-cta-container {
    flex-direction: column;
    text-align: center;
    height: 12rem;
  }

  .login-cta {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .login-cta h2 {
    width: 70%;
    font-size: 1.5rem;
    margin: 0;
  }

  .login-cta div {
    width: 80%;
    display: flex;
    justify-content: center;
  }

  .login-cta p {
    line-height: 2.3rem;
    font-size: 1.7rem;
  }

  .register-cta-container {
    flex-direction: column;
  }

  .landing-page-register-container {
    font-size: 1.5rem;
  }

  .comprehension-section p {
    margin: auto;
  }
}
